<template>
  <!-- eslint-disable vue/no-v-html -->
  <LandingPageLayout :page-color="true">
    <div class="banner">
      <div class="wrapper">
        <div class="inner">
          <div class="left">
            <div class="caption">
              <div ref="titleBanner" class="title">
                <h1>{{ t('lp.index.title') }}</h1>
              </div>
              <div ref="detailsBanner" class="details">
                <p v-html="t('lp.index.description_html')"></p>
              </div>
              <div ref="btnGroupBanner" class="btn-group">
                <a class="become-our-freelancer" :href="paths.recruiter">{{
                  t('lp.index.btn.recruiter')
                }}</a>
                <a class="looking-for-talent" :href="paths.client">{{
                  t('lp.index.btn.talent')
                }}</a>
              </div>
            </div>
          </div>
          <div class="right">
            <div ref="squareBlockBannerFirst" class="square-blue first"></div>
            <div ref="imgBanner" class="img">
              <img
                :src="require('images/views/lp/home/banner-home.jpg')"
                alt="Banner for Landing page"
              />
            </div>
            <div ref="squareBlockBannerSecond" class="square-blue second"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="features">
      <div class="wrapper">
        <div class="inner">
          <div ref="features" class="title">
            <h2>{{ t('lp.index.be_a_part_of_sourcedout.title') }}</h2>
          </div>
          <div class="content">
            <ul class="list">
              <li ref="itemOne" class="item">
                <div class="img">
                  <lottie
                    :options="recruiterOptions"
                    :height="250"
                    :width="250"
                    @v-on:animCreated="handleAnimation"
                  />
                </div>
                <div class="heading">
                  <h3>
                    {{
                      t('lp.index.be_a_part_of_sourcedout.list.recruiter.title')
                    }}
                  </h3>
                </div>
                <div class="text">
                  <p>
                    {{
                      t(
                        'lp.index.be_a_part_of_sourcedout.list.recruiter.description'
                      )
                    }}
                  </p>
                </div>
                <div class="link">
                  <a :href="paths.recruiter">
                    {{
                      t('lp.index.be_a_part_of_sourcedout.list.recruiter.btn')
                    }}
                    ></a
                  >
                </div>
              </li>
              <li ref="itemTwo" class="item">
                <div class="img">
                  <lottie
                    :options="clientOptions"
                    :height="250"
                    :width="250"
                    @v-on:animCreated="handleAnimation"
                  />
                </div>
                <div class="heading">
                  <h3>
                    {{
                      t('lp.index.be_a_part_of_sourcedout.list.client.title')
                    }}
                  </h3>
                </div>
                <div class="text">
                  <p>
                    {{
                      t(
                        'lp.index.be_a_part_of_sourcedout.list.client.description'
                      )
                    }}
                  </p>
                </div>
                <div class="link">
                  <a :href="paths.client">
                    {{ t('lp.index.be_a_part_of_sourcedout.list.client.btn') }}
                    ></a
                  >
                </div>
              </li>
              <li ref="itemThree" class="item">
                <div class="img">
                  <lottie
                    :options="talentOptions"
                    :height="250"
                    :width="250"
                    @v-on:animCreated="handleAnimation"
                  />
                </div>
                <div class="heading">
                  <h3>
                    {{
                      t('lp.index.be_a_part_of_sourcedout.list.searching.title')
                    }}
                  </h3>
                </div>
                <div class="text">
                  <p>
                    {{
                      t(
                        'lp.index.be_a_part_of_sourcedout.list.searching.description'
                      )
                    }}
                  </p>
                </div>
                <div class="link">
                  <a href="/talents">
                    {{
                      t('lp.index.be_a_part_of_sourcedout.list.searching.btn')
                    }}
                    ></a
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div ref="about_us" class="about-us">
      <div ref="block_about_us" class="square-blue"></div>
      <div class="wrapper">
        <div class="inner">
          <div class="main">
            <ul class="list">
              <li ref="video" class="video">
                <div class="square-blue"></div>
                <embed
                  width="400"
                  height="255"
                  src="https://www.youtube.com/embed/txuKcW7vBCk?si=1T-UqAq_FVjAWLKQ"
                />
              </li>
              <li ref="about_content" class="content">
                <div class="title">
                  <h2>{{ t('lp.index.about_sourcedout.title') }}</h2>
                </div>
                <ul class="list-content">
                  <li
                    v-html="t('lp.index.about_sourcedout.sourcedout_html')"
                  ></li>
                  <li
                    v-html="t('lp.index.about_sourcedout.employer_html')"
                  ></li>
                  <li
                    v-html="t('lp.index.about_sourcedout.recruiter_html')"
                  ></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div ref="review" class="review">
      <div class="wrapper">
        <div class="inner">
          <div ref="titleReview" class="title">
            <h2>{{ t('lp.index.what_they_say.title') }}</h2>
          </div>
          <div ref="slider" class="slider">
            <div>
              <Flicking
                :options="{ align: 'prev', circular: true }"
                :plugins="plugins_review"
              >
                <div class="container">
                  <div>
                    <div class="logo">
                      <div class="img">
                        <img
                          :src="
                            require('images/views/lp/recruiter/profile/profile-f.png')
                          "
                          alt="profile"
                        />
                      </div>
                    </div>
                    <div class="details">
                      <div class="text">
                        <p>
                          {{ t('lp.index.what_they_say.list.wanida.text') }}
                        </p>
                      </div>
                      <div class="client">
                        <div class="name">
                          {{ t('lp.index.what_they_say.list.wanida.name') }}
                        </div>
                        <div class="position">
                          {{ t('lp.index.what_they_say.list.wanida.position') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div>
                    <div class="logo">
                      <div class="img">
                        <img
                          :src="
                            require('images/views/lp/recruiter/profile/profile-f.png')
                          "
                          alt="profile"
                        />
                      </div>
                    </div>
                    <div class="details">
                      <div class="text">
                        <p>
                          {{ t('lp.index.what_they_say.list.sunanta.text') }}
                        </p>
                      </div>
                      <div class="client">
                        <div class="name">
                          {{ t('lp.index.what_they_say.list.sunanta.name') }}
                        </div>
                        <div class="position">
                          {{
                            t('lp.index.what_they_say.list.sunanta.position')
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div>
                    <div class="logo">
                      <div class="img">
                        <img
                          :src="
                            require('images/views/lp/recruiter/profile/profile-f.png')
                          "
                          alt="profile"
                        />
                      </div>
                    </div>
                    <div class="details">
                      <div class="text">
                        <p>
                          {{ t('lp.index.what_they_say.list.vachiraya.text') }}
                        </p>
                      </div>
                      <div class="client">
                        <div class="name">
                          {{ t('lp.index.what_they_say.list.vachiraya.name') }}
                        </div>
                        <div class="position">
                          {{
                            t('lp.index.what_they_say.list.vachiraya.position')
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div>
                    <div class="logo">
                      <div class="img">
                        <img
                          :src="
                            require('images/views/lp/recruiter/profile/profile-m.png')
                          "
                          alt="profile"
                        />
                      </div>
                    </div>
                    <div class="details">
                      <div class="text">
                        <p>
                          {{ t('lp.index.what_they_say.list.chatchai.text') }}
                        </p>
                      </div>
                      <div class="client">
                        <div class="name">
                          {{ t('lp.index.what_they_say.list.chatchai.name') }}
                        </div>
                        <div class="position">
                          {{
                            t('lp.index.what_they_say.list.chatchai.position')
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div slot="viewport" class="flicking-pagination"></div>
                <div slot="viewport">
                  <span class="flicking-arrow-prev is-circle">
                    <FontAwesomeIcon
                      :icon="{ prefix: 'fas', iconName: 'angle-left' }"
                    ></FontAwesomeIcon>
                  </span>
                  <span class="flicking-arrow-next is-circle">
                    <FontAwesomeIcon
                      :icon="{ prefix: 'fas', iconName: 'angle-right' }"
                    ></FontAwesomeIcon>
                  </span>
                </div>
              </Flicking>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="join_us" class="join-us">
      <div class="wrapper">
        <div class="inner">
          <div class="title">
            <h2>{{ t('lp.index.lets_get_started.title') }}</h2>
          </div>
          <div class="content">
            <ul class="list">
              <li ref="itemJoinOne" class="item">
                <div class="img">
                  <lottie
                    :options="recruiterOptions"
                    :height="250"
                    :width="250"
                    @v-on:animCreated="handleAnimation"
                  />
                </div>
                <div class="content">
                  <div class="heading">
                    <h3>
                      {{
                        t(
                          'lp.index.lets_get_started.become_freelance_recruiters'
                        )
                      }}
                    </h3>
                  </div>
                  <div class="link">
                    <a href="/recruiter_applications/new">Join Today!</a>
                  </div>
                </div>
              </li>
              <li ref="itemJoinTwo" class="item">
                <div class="img">
                  <lottie
                    :options="clientOptions"
                    :height="250"
                    :width="250"
                    @v-on:animCreated="handleAnimation"
                  />
                </div>
                <div class="content">
                  <div class="heading">
                    <h3>
                      {{
                        t(
                          'lp.index.lets_get_started.connect_with_high_quality_talents'
                        )
                      }}
                    </h3>
                  </div>
                  <div class="link">
                    <a href="/job_requests/new">Join Today!</a>
                  </div>
                </div>
              </li>
              <li ref="itemJoinThree" class="item">
                <div class="img">
                  <lottie
                    :options="talentOptions"
                    :height="250"
                    :width="250"
                    @v-on:animCreated="handleAnimation"
                  />
                </div>
                <div class="content">
                  <div class="heading">
                    <h3>
                      {{
                        t(
                          'lp.index.lets_get_started.seeking_out_for_new_job_opportunities'
                        )
                      }}
                    </h3>
                  </div>
                  <div class="link">
                    <a href="/talents/new">Join Today!</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div ref="faq" class="faq">
      <div class="wrapper">
        <div class="inner">
          <div ref="titleFaq" class="title">
            <h2>FAQ</h2>
          </div>
          <div ref="faq_container">
            <sui-accordion exclusive>
              <div class="main">
                <sui-accordion-title>
                  <h3>{{ t('lp.index.faq.one.question') }}</h3>
                  <div class="button">
                    <i class="las la-plus"></i>
                  </div>
                </sui-accordion-title>
                <sui-accordion-content>
                  <div v-html="t('lp.index.faq.one.answer_html')"></div>
                </sui-accordion-content>
              </div>
              <div class="main">
                <sui-accordion-title>
                  <h3>
                    {{ t('lp.index.faq.two.question') }}
                  </h3>
                  <div class="button">
                    <i class="las la-plus"></i>
                  </div>
                </sui-accordion-title>
                <sui-accordion-content>
                  <div v-html="t('lp.index.faq.two.answer_html')"></div>
                </sui-accordion-content>
              </div>
              <div class="main">
                <sui-accordion-title>
                  <h3>
                    {{ t('lp.index.faq.three.question') }}
                  </h3>
                  <div class="button">
                    <i class="las la-plus"></i>
                  </div>
                </sui-accordion-title>
                <sui-accordion-content>
                  <div v-html="t('lp.index.faq.three.answer_html')"></div>
                </sui-accordion-content>
              </div>
              <div class="main">
                <sui-accordion-title>
                  <h3>
                    {{ t('lp.index.faq.four.question') }}
                  </h3>
                  <div class="button">
                    <i class="las la-plus"></i>
                  </div>
                </sui-accordion-title>
                <sui-accordion-content>
                  <div v-html="t('lp.index.faq.four.answer_html')"></div>
                </sui-accordion-content>
              </div>
            </sui-accordion>
          </div>
        </div>
      </div>
    </div>
  </LandingPageLayout>
  <!-- eslint-enable vue/no-v-html -->
</template>
<script>
import Vue from 'vue'
import LandingPageLayout from 'components/layouts/LandingPage.vue'

import SuiVue from 'semantic-ui-vue'
Vue.use(SuiVue)

import { Flicking } from '@egjs/vue-flicking'
import '@egjs/flicking/dist/flicking.css'
import { Pagination, AutoPlay, Arrow } from '@egjs/flicking-plugins'
import '@egjs/flicking-plugins/dist/pagination.css'
import '@egjs/flicking-plugins/dist/arrow.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAngleRight)
library.add(faAngleLeft)

import Lottie from 'vue-lottie'
import * as recruiter_lottie from '../../../images/views/lp/home/lottie/home-recruit.json'
import * as client_lottie from '../../../images/views/lp/home/lottie/home-client.json'
import * as talent_lottie from '../../../images/views/lp/home/lottie/home-talent.json'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

import I18n from 'i18n/i18n'
import 'i18n/views/lp/en'
import 'i18n/views/lp/th'
import { i18n } from 'mixins/i18n'

window.I18n = I18n

export default {
  name: 'Home',

  components: {
    LandingPageLayout,
    Flicking,
    FontAwesomeIcon,
    lottie: Lottie,
  },
  mixins: [i18n],

  data() {
    return {
      recruiterOptions: { animationData: recruiter_lottie.default },
      clientOptions: { animationData: client_lottie.default },
      talentOptions: { animationData: talent_lottie.default },
      animationSpeed: 1,
      plugins_blog: [
        new AutoPlay({ duration: 3000, direction: 'NEXT', stopOnHover: false }),
        new Pagination({ type: 'bullet' }),
      ],
      plugins_review: [
        new Arrow(),
        new AutoPlay({ duration: 3000, direction: 'NEXT', stopOnHover: false }),
        new Pagination({ type: 'bullet' }),
      ],
      options: {
        autoplay: true,
      },
      // eslint-disable-next-line no-undef
      paths: paths || null,
      scrolling: false,
    }
  },

  mounted() {
    gsap.fromTo(
      this.$refs.titleBanner,
      { duration: 0.7, x: -1000, opacity: 0 },
      { duration: 0.7, x: 0, opacity: 1 }
    )
    gsap.fromTo(
      this.$refs.imgBanner,
      { duration: 0.9, x: 1000 },
      { duration: 0.9, x: 0 }
    )
    gsap.fromTo(
      this.$refs.detailsBanner,
      { duration: 1, x: -1000, opacity: 0 },
      { duration: 1, x: 0, opacity: 1 }
    )
    gsap.fromTo(
      this.$refs.btnGroupBanner,
      { duration: 1.9, opacity: 0 },
      { duration: 1.9, opacity: 1 }
    )
    gsap.fromTo(
      this.$refs.squareBlockBannerFirst,
      { duration: 2, opacity: 0 },
      { duration: 2, opacity: 1 }
    )
    gsap.fromTo(
      this.$refs.squareBlockBannerSecond,
      { duration: 2, opacity: 0 },
      { duration: 2, opacity: 1 }
    )

    let features = gsap.timeline()

    ScrollTrigger.create({
      trigger: this.$refs.features,
      start: 'top 80%',
      end: 'bottom 30%',
      animation: features,
      toggleActions: 'play none none none',
    })

    features
      .fromTo(
        this.$refs.itemOne,
        { y: 500, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )
      .fromTo(
        this.$refs.itemTwo,
        { y: 500, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )
      .fromTo(
        this.$refs.itemThree,
        { y: 500, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )

    let about_us = gsap.timeline()

    ScrollTrigger.create({
      trigger: this.$refs.about_us,
      start: 'top 80%',
      end: 'bottom 30%',
      animation: about_us,
      toggleActions: 'play none none none',
    })

    about_us
      .fromTo(
        this.$refs.block_about_us,
        { x: -1000, opacity: 0, duration: 0.7 },
        { x: 0, opacity: 1, duration: 0.7 },
        0.5
      )
      .fromTo(
        this.$refs.video,
        { x: -1000, opacity: 0, duration: 0.8 },
        { x: 0, opacity: 1, duration: 0.8 },
        0.5
      )
      .fromTo(
        this.$refs.about_content,
        { x: 1000, opacity: 0, duration: 0.9 },
        { x: 0, opacity: 1, duration: 0.9 },
        0.5
      )

    let review = gsap.timeline()

    ScrollTrigger.create({
      trigger: this.$refs.review,
      start: 'top 80%',
      end: 'bottom 30%',
      animation: review,
      toggleActions: 'play none none none',
    })

    review.fromTo(
      this.$refs.slider,
      { y: 100, opacity: 0, duration: 1.2 },
      { y: 0, opacity: 1, duration: 1.2 }
    )

    let join_us = gsap.timeline()

    ScrollTrigger.create({
      trigger: this.$refs.join_us,
      start: 'top 80%',
      end: 'bottom 30%',
      animation: join_us,
      toggleActions: 'play none none none',
    })

    join_us
      .fromTo(
        this.$refs.itemJoinOne,
        { y: 500, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )
      .fromTo(
        this.$refs.itemJoinTwo,
        { y: 500, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )
      .fromTo(
        this.$refs.itemJoinThree,
        { y: 500, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )

    let faq = gsap.timeline()

    ScrollTrigger.create({
      trigger: this.$refs.faq,
      start: 'top 90%',
      end: 'bottom 30%',
      animation: faq,
      toggleActions: 'play none none none',
    })

    faq
      .fromTo(
        this.$refs.titleFaq,
        { y: 100, opacity: 0, duration: 0.4 },
        { y: 0, opacity: 1, duration: 0.4 },
        0.5
      )
      .fromTo(
        this.$refs.faq_container,
        { y: 100, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 },
        0.5
      )
  },

  methods: {
    handleAnimation: function (anim) {
      this.anim = anim
    },
  },
}
</script>
<style scoped lang="scss">
@import 'stylesheets/global';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.banner {
  background-color: #5080c1;
  position: relative;

  .wrapper {
    flex: auto;
    margin: auto;
    max-width: 80%;
    padding: 0 2rem;
    width: auto;

    @include sp {
      max-width: unset;
      padding: 0;
    }

    .inner {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: auto;
      padding: 5rem 0;
      width: 100%;

      @include sp {
        padding: 0;
      }
    }
  }

  @include sp {
    padding-bottom: 2rem;
  }

  h1 {
    font-size: 5rem;
  }

  h1,
  p {
    color: $white;
  }

  .title {
    position: relative;
  }

  .right {
    display: flex;
    justify-content: end;
    padding-top: 2rem;
    position: absolute;
    right: 0;
    width: 40%;

    @include sp {
      max-width: unset;
      padding-bottom: 5rem;
      padding-top: 0;
      position: relative;
    }

    .img {
      height: 500px;
      margin-left: 1.5rem;
      position: relative;
      width: auto;
      z-index: 2;

      @include sp {
        height: auto;
        width: 80%;
        z-index: 1;
      }

      img {
        height: 100%;
        object-fit: cover;
        object-position: 65% 0;
        width: 100%;
      }
    }

    .square-blue {
      background: #7baff8;
      padding: 2.5rem;
      position: absolute;
      width: 25rem;
      z-index: 1;

      &.first {
        left: 0;
        top: 7px;

        @include sp {
          display: none;
        }
      }

      &.second {
        bottom: -1.25rem;
        right: 0;

        @include sp {
          bottom: 2.5rem;
          display: block;
          height: 100%;
          width: 50%;
          z-index: 0;
        }
      }
    }
  }

  .left {
    align-self: center;
    margin: 4rem 0;
    padding-top: 2rem;
    width: 50%;

    @include sp {
      margin: auto;
      max-width: 90%;
      padding: 0;
    }

    .caption {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
    }
  }

  .details {
    font-size: 1.25em;
    margin-top: 2rem;

    ::v-deep span {
      font-weight: 600;
    }
  }

  .btn-group {
    margin-top: 2rem;

    a {
      align-items: center;
      border-radius: 10rem;
      display: flex;
      font-weight: 500;
      justify-content: center;
      padding: 1.5rem 3rem;
      text-align: center;
      width: 19rem;

      &.become-our-freelancer {
        background-color: $white;
        color: $black;
      }

      &.looking-for-talent {
        background-color: #7baff8;
        color: $white;
        margin-left: 2rem;
      }

      &:hover {
        filter: contrast(150%);
      }
    }
  }
}

.features {
  overflow: hidden;
  .wrapper {
    flex: auto;
    margin: auto;
    max-width: 80%;
    padding: 0 2rem;
    width: auto;

    @include sp {
      max-width: 90%;
    }

    .inner {
      padding: 5rem 0;
    }
  }

  .title {
    margin-bottom: 1.5rem;

    h2 {
      color: $black;
      font-size: 2.5em;

      @include sp {
        font-size: 1.75em;
      }
    }
  }

  .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 0;

    @include sp {
      flex-direction: column;
    }

    > li {
      align-items: center;
      border-radius: 1.5rem;
      box-shadow: -2px 5px 22px rgba(30, 39, 46, 0.1);
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      padding: 3.5rem 2.5rem;
      width: 31.33%;

      @include sp {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        width: 100%;
      }
    }

    .heading {
      margin: 2rem 0 1rem;

      h3 {
        color: $black;
        font-size: 1.5em;
        text-align: center;
      }
    }

    .text {
      font-size: 1.2em;
      margin-bottom: 1rem;
      text-align: center;

      @include sp {
        font-size: 1em;
      }
    }

    .link {
      margin-top: 2rem;

      a {
        font-weight: 500;
      }
    }
  }
}

.about-us {
  position: relative;
  /* stylelint-disable no-descending-specificity */
  .square-blue {
    background: #7baff8;
    height: 100%;
    padding: 2.5rem;
    position: absolute;
    width: 30%;
  }

  /* stylelint-enable no-descending-specificity */
  .wrapper {
    flex: auto;
    margin: auto;
    max-width: 80%;
    padding: 0 2rem;
    width: auto;

    @include sp {
      max-width: 90%;
    }

    .inner {
      padding: 5rem 0;
    }
  }

  .title {
    margin-bottom: 3rem;

    h2 {
      color: $black;
      font-size: 2.5em;

      @include sp {
        font-size: 1.75em;
      }
    }
  }

  .main {
    position: relative;
  }

  .list {
    display: flex;
    flex-direction: row;
    list-style: none;

    @include sp {
      flex-direction: column;
    }

    > li {
      &.video {
        padding-top: 11rem;
        width: 40%;

        @include sp {
          width: 100%;
        }

        .square-blue {
          display: none;
        }
      }

      &.content {
        margin-left: 3rem;
        width: 60%;

        @include sp {
          width: 100%;
        }
      }
    }
  }

  .list-content {
    > li {
      font-size: 1.2em;
      line-height: 2rem;
      margin-bottom: 1rem;

      @include sp {
        font-size: 1em;
      }

      ::v-deep span {
        color: #5080c1;
        font-weight: 600;
      }
    }
  }
}

.blog {
  .wrapper {
    flex: auto;
    margin: auto;
    max-width: 80%;
    padding: 0 2rem;
    width: auto;

    @include sp {
      max-width: 90%;
    }

    .inner {
      padding: 5rem 0;
    }
  }

  .title {
    margin-bottom: 1.5rem;

    h2 {
      color: $black;
      font-size: 2.5em;

      @include sp {
        font-size: 1.75em;
      }
    }
  }

  .slider {
    margin-left: -1rem;
  }

  .container {
    padding-left: -1rem;
    padding-right: 0;
    width: 33.33%;

    @include sp {
      width: 50%;
    }

    .heading {
      margin: 1.5rem 0;

      h3 {
        color: $black;
        font-size: 1.5em;
        font-weight: 700;

        @include sp {
          font-size: 1.2em;
        }
      }
    }

    .text {
      font-size: 1.2em;

      @include sp {
        font-size: 1em;
      }
    }

    .link {
      margin-top: 1.5rem;

      a {
        font-weight: 500;
      }
    }
  }

  ::v-deep {
    .flicking-pagination {
      margin-top: 6rem;
      position: relative;
    }

    .flicking-pagination-bullet-active {
      background-color: #7baff8;
    }
  }
}

.review {
  background-color: #f6f9fc;

  .wrapper {
    flex: auto;
    margin: auto;
    max-width: 80%;
    padding: 0 2rem;
    width: auto;

    @include sp {
      max-width: 90%;
    }

    .inner {
      padding: 5rem 0;
    }
  }

  .title {
    margin-bottom: 1.5rem;

    h2 {
      color: $black;
      font-size: 2.5em;

      @include sp {
        font-size: 1.85em;
      }
    }
  }

  ::v-deep {
    .flicking-arrow-prev,
    .flicking-arrow-next {
      display: flex;
      height: 40px;
      width: 40px;

      &::after,
      &::before {
        content: none;
      }

      &.is-circle {
        background-color: #7baff8;
      }

      svg {
        color: $white;
        display: flex;
        font-size: 1.75em;
        margin: auto;
      }

      @include sp {
        display: none;
      }
    }

    .flicking-pagination {
      @include sp {
        margin-top: 6rem;
        position: relative;
      }
    }

    .flicking-pagination-bullet-active {
      background-color: #7baff8;
    }
  }

  .container {
    max-width: none;
    padding: 9rem;
    width: 100%;

    @include sp {
      padding: 0;
    }

    > div {
      display: flex;

      @include sp {
        display: block;
      }
    }

    .details {
      margin-left: 2rem;
      width: 80%;

      .text,
      .position {
        font-size: 1.15em;

        @include sp {
          font-size: 1em;
        }
      }

      .client {
        margin-top: 1rem;

        .name {
          font-size: 1.2em;
          font-weight: 500;
          margin-bottom: 0.25rem;

          @include sp {
            font-size: 1.15em;
          }
        }
      }

      @include sp {
        width: 100%;
      }
    }
  }

  .logo {
    width: 20%;

    .img {
      height: 150px;
      width: 150px;

      img {
        border-radius: 50%;
        height: 100%;
        object-fit: cover;
        transform: translate3d(0, 0, 0);
        width: 100%;
      }

      @include sp {
        margin: auto;
      }
    }

    @include sp {
      margin: 2rem 0;
      width: 100%;
    }
  }
}

.join-us {
  background-color: #eff6fe;

  .wrapper {
    flex: auto;
    margin: auto;
    max-width: 80%;
    padding: 0 2rem;
    width: auto;

    @include sp {
      max-width: 90%;
    }

    .inner {
      padding: 5rem 0;
    }
  }

  .title {
    margin-bottom: 1.5rem;

    h2 {
      color: $black;
      font-size: 2.5em;

      @include sp {
        font-size: 1.85em;
      }
    }
  }

  .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 0;

    @include sp {
      flex-direction: column;
    }

    > li {
      background-color: $white;
      border-radius: 1.5rem;
      box-shadow: -2px 5px 22px rgba(30, 39, 46, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-bottom: 1rem;
      padding: 3.5rem 2.5rem;
      width: 31.33%;

      @include sp {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
        padding: 1.5rem 1rem;
        width: 100%;
      }
    }

    .content {
      @include sp {
        width: 50%;
      }
    }

    .heading {
      margin-bottom: 1rem;
      text-align: center;

      h3 {
        color: $black;
        font-size: 1.25em;
      }
    }

    .link {
      margin-top: 2rem;
      width: 100%;

      a {
        background: #7baff8;
        border-radius: 10rem;
        color: white;
        font-weight: 500;
        padding: 1.5rem;
        text-align: center;
        width: auto;

        &:hover {
          box-shadow: none;
          filter: contrast(150%);
          text-decoration: none;
        }
      }
    }
  }
}

.faq {
  .wrapper {
    flex: auto;
    margin: auto;
    max-width: 80%;
    padding: 0 2rem;
    width: auto;

    @include sp {
      max-width: 90%;
    }

    .inner {
      padding: 5rem 0;
    }
  }

  .title {
    margin-bottom: 1.5rem;

    h2 {
      color: $black;
      font-size: 2em;

      @include sp {
        font-size: 1.85em;
      }
    }
  }

  ::v-deep {
    .accordion {
      .main {
        border-bottom: 1px solid #c3c3c3;

        &:last-child {
          border: 0;
        }

        h3 {
          color: $black;
          font-weight: 500;
        }

        .title {
          display: flex;
          justify-content: space-between;
          margin: 0;
          padding: 1.95rem 0;

          .button {
            i {
              color: #5080c1;
              font-size: 1.5em;
              transition: transform 0.3s ease-in-out;
            }
          }

          &.active {
            padding-bottom: 0.75rem;

            .button {
              i {
                transform: rotate(-135deg);
              }
            }
          }
        }

        .content {
          height: 0;
          opacity: 0;
          overflow: hidden;
          transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;

          p {
            padding-bottom: 1rem;
            padding-right: 1.5rem;
          }

          &.active {
            height: auto;
            opacity: 1;
          }
        }
      }
    }

    .ui.accordion {
      .title ~ .content {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .banner {
    h1 {
      font-size: 3.5rem;
    }

    .right {
      .img {
        height: 400px;
      }
    }

    .left {
      .details {
        p {
          font-size: 1rem;
        }
      }

      .btn-group {
        a {
          margin: auto;
          padding: 1.25rem 1.5rem;
          width: 15rem;
        }
      }
    }
  }

  .about-us {
    overflow: hidden;
    .main {
      .list {
        > li {
          &.video {
            width: 50%;
          }

          &.content {
            margin-left: 5rem;
            padding: 0;
            width: 50%;
          }
        }
      }
    }
  }

  .review {
    .container {
      .details {
        margin-left: 5rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .banner {
    h1 {
      font-size: 2.85rem;
    }
  }

  .about-us {
    .main {
      .list {
        > li {
          &.video {
            margin-left: -3rem;
          }

          &.content {
            margin-left: 6rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .banner {
    flex-direction: column;
    padding-top: 10rem;

    .left,
    .right {
      width: 100%;
    }

    .left {
      order: 2;

      .caption {
        padding: 1.5rem 1rem;
      }

      .btn-group {
        display: block;
        width: 100%;

        a {
          width: 100%;

          &.looking-for-talent {
            margin-left: 0;
            margin-top: 1rem;
          }
        }
      }
    }

    .right {
      .img {
        height: auto;
        width: 80%;
      }
    }
  }

  .about-us {
    .square-blue {
      display: none;
    }

    .wrapper {
      max-width: none;
      padding: 0;
    }

    .main {
      .list {
        > li {
          &.video {
            display: flex;
            justify-content: end;
            padding: 4rem 0;
            position: relative;
            width: 100%;

            .square-blue {
              background: #7baff8;
              display: block;
              height: 100%;
              left: 0;
              padding: 2.5rem;
              position: absolute;
              top: 0;
              width: 80%;
              z-index: -1;
            }

            iframe {
              z-index: 1;
            }
          }

          &.content {
            margin: auto;
            max-width: 90%;
            padding: 0 2rem;
            width: auto;
          }
        }
      }

      .content {
        .title {
          margin-top: 3rem;
        }
      }
    }
  }

  .review {
    .container {
      .details {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .join-us {
    .img {
      /* stylelint-disable declaration-no-important */
      > div {
        height: 170px !important;
        width: 170px !important;
      }
      /* stylelint-enable declaration-no-important */
    }
  }
}

@media screen and (max-width: 480px) {
  .banner {
    .right {
      .img {
        height: auto;
        width: 90%;
      }
    }
  }

  .join-us {
    .list {
      .content {
        width: 70%;
      }

      .link {
        a {
          padding: 1rem;
        }
      }

      .heading {
        h3 {
          font-size: 1em;
        }
      }

      .img {
        > div {
          /* stylelint-disable declaration-no-important */
          height: 150px !important;
          width: 150px !important;
          /* stylelint-enable declaration-no-important */
        }
      }
    }
  }

  .about-us {
    .main {
      .list {
        > li {
          &.video {
            margin: 0;

            iframe {
              height: 240px;
              width: 400px;
            }
          }
        }
      }
    }
  }

  .blog {
    .container {
      width: 100%;
    }
  }
}

@media screen and (max-width: 320px) {
  .banner {
    h1 {
      font-size: 1.85em;
    }
  }
}

/* stylelint-disable  no-descending-specificity */
:lang(th) {
  .banner {
    h1 {
      font-size: 4rem;
    }
  }

  .faq {
    ::v-deep {
      .accordion {
        .main {
          h3 {
            font-size: 1.2em;
          }
        }
      }
    }
  }
}
/* stylelint-enable  no-descending-specificity */
</style>

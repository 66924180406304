<template>
  <nav :class="isBlue ? 'bg-havelock-blue' : 'bg-white'">
    <div class="container" :class="{ active: lockScroll }">
      <div class="logo">
        <a :href="paths.home">
          <img
            v-if="isBlue"
            :src="require('images/logo/logo-wh.png')"
            :alt="t('layouts.header.logo_banner_alt')"
          />
          <img
            v-else
            :src="require('images/logo/logo.png')"
            :alt="t('layouts.header.logo_banner_alt')"
          />
        </a>
      </div>
      <div v-click-outside="onClickOutside" class="center">
        <div class="hamburger" :class="{ active: drawer }" @click="toggleMenu">
          <span></span>
        </div>
        <div class="desktop">
          <div class="menu">
            <ul class="list">
              <li class="home">
                <a
                  :class="current === 'lp' ? 'current' : ''"
                  :href="paths.home"
                  >{{ t('lp.header.home') }}</a
                >
              </li>
              <li class="recruiter">
                <a
                  :class="current === 'recruiter' ? 'current' : ''"
                  :href="paths.recruiter"
                  >{{ t('lp.header.recruiter') }}</a
                >
              </li>
              <li class="client">
                <a
                  :class="current === 'client' ? 'current' : ''"
                  :href="paths.client"
                  >{{ t('lp.header.employer') }}</a
                >
              </li>
              <li class="talent">
                <a href="/talents">{{ t('lp.header.talent') }}</a>
              </li>
              <li class="about-us">
                <a
                  :class="current === 'about_us' ? 'current' : ''"
                  :href="paths.aboutUs"
                  >{{ t('lp.header.about_us') }}</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div
          v-scroll-lock="lockScroll"
          class="mobile"
          :class="{ active: drawer }"
        >
          <div class="menu">
            <ul class="list">
              <li class="home">
                <a
                  :class="current === 'lp' ? 'current' : ''"
                  :href="paths.home"
                  >{{ t('lp.header.home') }}</a
                >
              </li>
              <li class="recruiter">
                <a
                  :class="current === 'recruiter' ? 'current' : ''"
                  :href="paths.recruiter"
                  >{{ t('lp.header.recruiter') }}</a
                >
              </li>
              <li class="client">
                <a
                  :class="current === 'client' ? 'current' : ''"
                  :href="paths.client"
                  >{{ t('lp.header.employer') }}</a
                >
              </li>
              <li class="talent">
                <a href="/talents">{{ t('lp.header.talent') }}</a>
              </li>
              <li class="about-us">
                <a
                  :class="current === 'about_us' ? 'current' : ''"
                  :href="paths.aboutUs"
                  >{{ t('lp.header.about_us') }}</a
                >
              </li>
            </ul>
          </div>
          <div
            class="languages"
            :class="isBlue ? 'border-bg-maya-blue' : 'no-border'"
          >
            <sui-dropdown :text="lang.toUpperCase()" button pointing>
              <sui-dropdown-menu>
                <sui-dropdown-item @click="setLocale('en')"
                  >English
                </sui-dropdown-item>
                <sui-dropdown-item @click="setLocale('th')"
                  >Thai
                </sui-dropdown-item>
              </sui-dropdown-menu>
            </sui-dropdown>
          </div>
          <div class="user">
            <div class="sign-up">
              <a :href="paths.signUpChoice">{{
                t('lp.header.user.sign_up')
              }}</a>
            </div>
            <div class="log-in">
              <a href="/users/sign_in">{{ t('lp.header.user.log_in') }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="side">
        <div
          class="languages"
          :class="isBlue ? 'border-bg-maya-blue' : 'no-border'"
        >
          <sui-dropdown :text="lang.toUpperCase()" button pointing>
            <sui-dropdown-menu>
              <sui-dropdown-item @click="setLocale('en')"
                >English
              </sui-dropdown-item>
              <sui-dropdown-item @click="setLocale('th')"
                >Thai
              </sui-dropdown-item>
            </sui-dropdown-menu>
          </sui-dropdown>
        </div>
        <div class="user">
          <div class="log-in">
            <a
              :class="isBlue ? 'text-white' : 'text-black'"
              href="/users/sign_in"
              >{{ t('lp.header.user.log_in') }}</a
            >
          </div>
          <div
            class="sign-up"
            :class="isBlue ? 'bg-maya-blue ' : 'bg-havelock-blue'"
          >
            <a
              :class="isBlue ? 'bg-maya-blue ' : 'bg-havelock-blue '"
              :href="paths.signUpChoice"
              >{{ t('lp.header.user.sign_up') }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Vue from 'vue'

import SuiVue from 'semantic-ui-vue'

import vClickOutside from 'v-click-outside'
import { directive } from 'vue-body-scroll-lock'
import I18n from 'i18n/i18n'
import 'i18n/views/lp/en'
import 'i18n/views/lp/th'
import { i18n } from 'mixins/i18n'

Vue.use(SuiVue)
Vue.use(vClickOutside)

window.I18n = I18n

export default {
  directives: {
    scrollLock: directive,
  },

  mixins: [i18n],

  props: {
    isBlue: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      drawer: false,
      lockScroll: false,
      lang: window.I18n.locale,
      // eslint-disable-next-line no-undef
      paths: paths,
      current: 'lp',
    }
  },
  mounted() {
    this.current = window.location.href.split('/').pop()
  },
  methods: {
    setLocale(locale) {
      this.lang = locale
      let path = window.location.pathname
      const lang_param = path.split('/')

      if (lang_param.includes('en') || lang_param.includes('th')) {
        lang_param[1] = this.lang
        window.location.pathname = lang_param.join('/')
      } else {
        lang_param[0] = this.lang
        window.location.pathname = lang_param.join('/')
      }
    },
    toggleMenu() {
      this.drawer = !this.drawer
      this.lockScroll = !this.lockScroll
    },
    onClickOutside() {
      this.drawer = false
      this.lockScroll = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'stylesheets/global';

/* stylelint-disable no-descending-specificity */
nav {
  a {
    font-size: 1em;
    font-weight: 500;

    @include sp {
      font-size: 0.8em;
    }
  }

  @include sp {
    left: 0;
    min-width: 320px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  &.bg-havelock-blue {
    background-color: #5080c1;

    .center {
      a {
        color: $white;

        &.current {
          color: #7baff8;
        }

        &:hover {
          color: #7baff8;
        }
      }
    }
  }

  &.bg-white {
    background-color: $white;

    .center {
      a {
        color: #1a1a1a;

        &.current {
          color: #5080c1;
        }

        &:hover {
          color: #5080c1;
        }
      }
    }

    ::v-deep .ui.dropdown {
      background-color: $white;

      .text {
        color: #5080c1;
      }
    }

    ::v-deep .ui.active.button {
      &:hover {
        background-color: $white;
      }
    }

    .hamburger {
      span,
      &::before,
      &::after {
        border-bottom: 2px solid #5080c1;
      }

      &.active {
        span,
        &::before,
        &::after {
          border-bottom: 2px solid $white;
        }
      }
    }

    @include sp {
      .center {
        a {
          color: $white;

          &:hover {
            color: #7baff8;
          }
        }
      }
    }
  }

  .container {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: 1600px;
    padding: 1.5rem 2rem;

    &::before {
      background-color: $black;
      content: '';
      left: 0;
      opacity: 0;
      position: fixed;
      right: 0;
      top: 0;
      transition: opacity 0.3s ease-in-out;
    }

    &.active {
      &::before {
        bottom: 0;
        opacity: 0.8;
        transition-delay: 0.3s;
        z-index: 1;
      }
    }

    @include sp {
      padding: 1.5rem 1rem;
    }
  }

  .logo {
    img {
      width: 150px;

      @include sp {
        width: 120px;
      }
    }
  }

  .center {
    margin: auto;

    .menu {
      .list {
        display: flex;

        > li {
          margin-left: 2rem;
        }

        @include sp {
          display: grid;
        }
      }
    }

    .desktop {
      @include sp {
        display: none;
      }
    }

    .mobile {
      background-color: #0e1111;
      display: none;
      height: 100%;
      overflow-x: hidden;
      padding-top: 60px;
      position: fixed;
      right: -100%;
      top: 0;
      transition: right 0.5s ease-in-out;
      width: 40%;
      z-index: 1;

      .list {
        > li {
          margin-bottom: 1.5rem;
        }
      }

      .languages {
        margin-left: 2rem;
        padding: 0;

        .list {
          display: flex;
          margin-left: 1.5rem;
        }

        li {
          cursor: pointer;

          &.active {
            background-color: #7baff8;
            border-radius: 0.5rem;
            padding: 0.5rem;
            transition: opacity 0.3s ease-in-out;

            a {
              &:hover {
                color: $white;
              }
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      @include sp {
        display: block;

        .languages {
          display: block;
        }

        &.active {
          right: 0;
        }
      }

      .user {
        justify-content: left;
        margin-left: 2rem;
        margin-top: 1.5rem;
        .log-in {
          a {
            color: $white;
          }
        }

        .sign-up {
          margin-right: 1rem;
          a {
            border: $white solid 1px;
            border-radius: 2rem;
            color: $white;
            font-size: 0.8em;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .side {
    align-items: center;
    display: flex;
  }

  .languages {
    margin-right: 1rem;

    &.no-border {
      a {
        color: #5080c1;
        font-size: 0.8em;

        &:hover {
          color: $black;
        }
      }
    }

    &.border-bg-maya-blue {
      transition: opacity 0.3s ease-in-out;

      li {
        &.active {
          background-color: #7baff8;
          border-radius: 0.5rem;
          line-height: 1.15rem;
          padding: 0.25rem;
        }
      }

      a {
        color: $white;
        font-size: 0.8em;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    @include sp {
      display: none;
    }
  }

  .user {
    align-items: center;
    display: flex;
    justify-content: center;

    .log-in {
      margin-right: 1rem;

      a {
        &.text-black {
          color: $black;

          @include sp {
            color: #5080c1;
          }
        }

        &.text-white {
          color: $white;
        }
      }
    }

    .sign-up {
      a {
        border-radius: 2rem;
        color: $white;
        font-size: 1em;
        line-height: 2.5rem;
        outline: none;
        overflow: hidden;
        padding: 0 1.5rem;
        text-align: center;
        text-overflow: ellipsis;
        transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
        white-space: nowrap;

        &:hover {
          opacity: 0.8;
        }

        @include sp {
          font-size: 0.8em;
        }
      }

      &.bg-maya-blue {
        a {
          background-color: #7baff8;
        }
      }

      &.bg-havelock-blue {
        a {
          background-color: #5080c1;
        }
      }
    }

    @include sp {
      margin-right: 4rem;
    }
  }

  .hamburger {
    border-radius: 50%;
    cursor: pointer;
    display: none;
    font-size: 20px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 10px;
    transition: all 0.3s ease-in-out;
    width: 50px;
    z-index: 2;

    span,
    &::before,
    &::after {
      border-bottom: 2px solid $white;
      content: '';
      left: 30%;
      position: absolute;
      top: calc(50% - 1px);
      transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
      width: 40%;
    }

    &::before {
      transform: translateY(-8px);
    }

    &::after {
      transform: translateY(8px);
    }

    @include sp {
      display: block;
    }

    &.active {
      span {
        transform: scaleX(0);
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  ::v-deep .ui.button {
    font-family: 'Poppins', sans-serif;
  }

  ::v-deep .ui.active.button {
    &:hover {
      background-color: #7baff8;
    }
  }

  ::v-deep .ui.dropdown {
    background-color: #7baff8;
    padding: 0.25rem;

    .text {
      color: $white;
      font-size: 0.8em;
      font-weight: 600;
    }

    > .icon {
      display: none;
    }

    .menu {
      border: 1px solid $black;
      border-radius: 0;
      margin-top: 0.5rem;
      width: 8rem;

      > .item {
        font-size: 0.8em;
      }
    }
  }
}

/* stylelint-enable no-descending-specificity */

@media screen and (max-width: 480px) {
  nav {
    .center {
      .mobile {
        width: 70%;
      }
    }
  }
}
</style>

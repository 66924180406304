<template>
  <div>
    <Header :is-blue="pageColor" />
    <slot />
    <Footer />
  </div>
</template>

<script>
import Header from 'components/layouts/lp/Header.vue'
import Footer from 'components/layouts/lp/Footer.vue'

export default {
  name: 'LandingPageLayout',

  components: {
    Header,
    Footer,
  },

  props: {
    pageColor: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

export default {
  computed: {
    telNoToHref: function () {
      return function (telNo) {
        return `tel:${telNo.replace(/[^+0-9]/g, '')}`
      }
    },
  },

  methods: {
    statusClass(status) {
      const conf = {
        Employed: 'employed',
        Reviewing: 'reviewing',
        'Under selection': 'selection',
        'Interview requested': 'request',
        'Interview scheduled': 'scheduled',
        'Waiting for interview result': 'waiting',
        Offering: 'offering',
        Refused: 'refused',
      }
      return conf[status]
    },

    closedStatus(closed) {
      const conf = {
        no: 'In Progress',
        yes: 'Closed',
      }
      return conf[closed]
    },
  },
}

<template>
  <footer>
    <div class="wrapper">
      <div class="inner">
        <div class="main">
          <ul class="foot-list">
            <li class="logo">
              <a href="/">
                <img
                  :src="require('images/logo/logo-wh.png')"
                  :alt="t('layouts.footer.logo_banner_alt')"
                />
              </a>
            </li>
            <li class="link-group">
              <ul class="list">
                <li class="phone">
                  <FontAwesomeIcon
                    :icon="{ prefix: 'fas', iconName: 'phone-volume' }"
                  ></FontAwesomeIcon>
                  <a class="link icon-link" :href="telNoToHref(telNo)">
                    {{ telNo }}</a
                  >
                </li>
                <li class="email">
                  <FontAwesomeIcon
                    :icon="{ prefix: 'fas', iconName: 'envelope' }"
                  ></FontAwesomeIcon>
                  <a class="link icon-link">{{ mailAddress }}</a>
                </li>
                <li class="location">
                  <FontAwesomeIcon
                    :icon="{ prefix: 'fas', iconName: 'map-marker-alt' }"
                  ></FontAwesomeIcon>
                  <div class="location-detail">
                    801 8th Floor, Mercury Tower, 540 Ploenchit Road, Lumphini,
                    Pathum Wan, Bangkok 10330
                  </div>
                </li>
                <li class="socials">
                  <div class="title">{{ t('lp.footer.follow_us') }}</div>
                  <div class="socials-group">
                    <ul class="list">
                      <li class="facebook">
                        <a :href="facebookUrl" target="_blank">
                          <FontAwesomeIcon
                            :icon="{ prefix: 'fab', iconName: 'facebook-f' }"
                          ></FontAwesomeIcon>
                        </a>
                      </li>
                      <li class="youtube">
                        <a :href="youtubeUrl" target="_blank">
                          <FontAwesomeIcon
                            :icon="{ prefix: 'fab', iconName: 'youtube' }"
                          ></FontAwesomeIcon>
                        </a>
                      </li>
                      <li class="linkedin">
                        <a :href="linkedinUrl" target="_blank">
                          <FontAwesomeIcon
                            :icon="{ prefix: 'fab', iconName: 'linkedin-in' }"
                          ></FontAwesomeIcon>
                        </a>
                      </li>
                      <li class="tiktok">
                        <a :href="tiktokUrl" target="_blank">
                          <FontAwesomeIcon
                            :icon="{ prefix: 'fab', iconName: 'tiktok' }"
                          ></FontAwesomeIcon>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <ul class="list-link">
                <li class="about-us">
                  <a :href="paths.aboutUs">{{ t('lp.footer.about_us') }}</a>
                </li>
                <li class="recruiter">
                  <a :href="paths.recruiter">{{ t('lp.footer.recruiter') }}</a>
                </li>
                <li class="client">
                  <a :href="paths.client">{{ t('lp.footer.employer') }}</a>
                </li>
                <li class="talent">
                  <a href="/talents">{{ t('lp.footer.talent') }}</a>
                </li>
              </ul>
            </li>
            <li class="copyright">
              <p>Copyright © 2021 Reeracoen. All rights reserved.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// I18n
import I18n from 'i18n/i18n'
import 'i18n/views/lp/en'
import 'i18n/views/lp/th'
import { i18n } from 'mixins/i18n'

window.I18n = I18n

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebookF,
  faLinkedinIn,
  faLine,
  faYoutube,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons'
import {
  faEnvelope,
  faPhoneVolume,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFacebookF)
library.add(faLinkedinIn)
library.add(faLine)
library.add(faYoutube)
library.add(faEnvelope)
library.add(faPhoneVolume)
library.add(faMapMarkerAlt)
library.add(faTiktok)

import settings from 'consts/settings.js.erb'
import helpers from 'mixins/helpers'

export default {
  components: {
    FontAwesomeIcon,
  },

  mixins: [i18n, helpers],

  data() {
    let paths = {
      language: {
        en: '#{language_url(:en)}',
        th: '#{language_url(:th)}',
      },
      home: '#{root_path}',
      recruiter: '#{recruiter_path}',
      client: '#{client_path}',
      aboutUs: '#{about_us_path}',
      signUpChoice: '#{sign_up_choice_path}',
    }

    return {
      reeracoenWebsite: {
        name: 'Reeracoen Thailand',
        url: settings.social_url.reeracoen,
      },
      facebookUrl: settings.social_url.facebook_sourcedout,
      linkedinUrl: settings.social_url.linkedin,
      lineUrl: settings.social_url.line,
      youtubeUrl: settings.social_url.youtube,
      tiktokUrl: settings.social_url.tiktok,
      mailAddress: settings.contact.email_sourcedout,
      telNo: settings.contact.tel,
      // eslint-disable-next-line no-undef
      paths: paths,
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'stylesheets/global';

footer {
  background-color: #7baff8;
  padding: 2rem 0;
  width: 100%;

  .foot-list {
    display: flex;
    flex-direction: column;

    li {
      margin-bottom: 3rem;

      @include sp {
        margin-bottom: 1.5rem;
      }
    }

    .btn-group {
      display: flex;
      justify-content: space-between;

      a {
        align-items: center;
        background: $white;
        border-radius: 10rem;
        display: flex;
        font-weight: 500;
        justify-content: center;
        padding: 1.5rem 2rem;
        text-align: center;
        transition: opacity 0.3s ease-in-out;
        width: 250px;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .logo {
      img {
        opacity: 0.8;
        width: 200px;

        @include sp {
          width: 150px;
        }
      }
    }

    .link-group {
      display: flex;
      width: 100%;

      .list-link {
        flex: 40%;
        margin-left: 8rem;

        > li {
          margin-bottom: 1rem;
        }

        a {
          color: $white;
          font-weight: 500;
          opacity: 0.8;

          &:hover,
          &:focus {
            opacity: 1;
          }
        }
      }

      .list {
        flex: auto;

        li {
          display: flex;
          margin-bottom: 1.5rem;

          @include sp {
            margin-bottom: 1rem;
          }
        }

        .link {
          color: $white;
          font-size: 1em;
          font-weight: 500;
          opacity: 0.8;

          &:hover,
          &:focus {
            opacity: 1;
          }
        }

        .location-detail {
          color: $white;
          font-size: 1em;
          font-weight: 500;
          opacity: 0.8;
        }

        .fa-envelope {
          font-size: 15px;
        }

        .fa-map-marker-alt,
        .fa-phone-volume {
          font-size: 20px;
        }

        .fa-envelope,
        .fa-map-marker-alt,
        .fa-phone-volume {
          color: #5080c1;
          margin-right: 1.25rem;
        }

        .phone {
          .fa-phone-volume {
            transform: rotate(-45deg);
          }
        }

        .socials {
          display: block;

          .title {
            color: $white;
            font-size: 1em;
            font-weight: 500;
            margin-bottom: 1rem;
          }

          .socials-group {
            .list {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;

              li {
                margin-bottom: 0;
              }

              .fa-facebook-f,
              .fa-youtube,
              .fa-linkedin-in,
              .fa-tiktok {
                font-size: 1.5rem;
              }

              a {
                color: #5080c1;
                font-size: 1em;
                margin-right: 1.75rem;
                opacity: 0.8;

                &:hover,
                &:focus {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }

    .copyright {
      color: $white;
      font-size: 0.85rem;
      letter-spacing: 1px;
      margin-bottom: 0;
      margin-top: 2rem;
      opacity: 0.65;
      text-align: center;

      @include sp {
        font-size: 10px;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  footer {
    .foot-list {
      .btn-group {
        a {
          width: 200px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  footer {
    .foot-list {
      .btn-group {
        a {
          width: 250px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  footer {
    .foot-list {
      .btn-group {
        display: block;
        margin: auto;

        a {
          margin-bottom: 1.25rem;
          padding: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  footer {
    .foot-list {
      .btn-group {
        display: block;
        margin: auto;

        a {
          margin-bottom: 1.25rem;
          padding: 1rem;
        }
      }

      .link-group {
        display: block;

        .list {
          margin-bottom: 2rem;

          .link,
          .location-detail {
            font-size: 0.9em;
          }

          .socials {
            margin-top: 2rem;

            .title {
              font-size: 0.9em;
            }
          }
        }

        .list-link {
          margin: 0;

          > li {
            margin-bottom: 1.5rem;
          }

          a {
            font-size: 0.9em;
          }
        }
      }

      .copy-right {
        margin-top: 2rem;
      }
    }
  }
}
</style>
